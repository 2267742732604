import { useCallback, useEffect, useState } from 'react';

import { decreaseElement, increaseElement, resetBasket, resetPromocode, setPromocode } from '~/lib/cart/slice';
import { useAppDispatch, useAppSelector } from '~/lib/hooks';
import { useCookieValue } from '~/utils/hooks';

import { useLazyVerifyPromocodesQuery } from './api';

export const usePromocode = () => {
    const [promocodeValue, setPromocodeValue] = useState('');
    const [promocodeError, setPromocodeError] = useState('');
    const selectedProducts = useAppSelector((state) => state.cart).data;

    const selectedCart = selectedProducts.map((el) => ({
        product_id: el.id,
        quantity: el.quantity,
    }));

    const [triggerPromocode] = useLazyVerifyPromocodesQuery();
    const dispatch = useAppDispatch();

    const promocode = useCookieValue('promocode');

    const applyPromocode = async () => {
        setPromocodeError('');
        if (!selectedCart.length) {
            return;
        }
        const getCookieValue = (cookieName: string) => {
            const cookies = document.cookie.split('; ');
            const cookie = cookies.find((c) => c.startsWith(`${cookieName}=`));
            return cookie ? decodeURIComponent(cookie.split('=')[1]) : null;
        };

        const promocodeToApply = getCookieValue('promocode') || promocodeValue;

        if (!promocodeToApply) {
            setPromocodeError('Введите значение');
            return;
        }
        const { data, error } = await triggerPromocode({
            code: promocodeToApply,
            positions: selectedCart,
        });

        if (data) {
            const date = new Date();
            date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // храним промокод в куки только сутки
            const expires = `expires=${date.toUTCString()}`;
            document.cookie = `promocode=${promocodeToApply}; ${expires}; path=/; secure=true`;
            dispatch(setPromocode({ id: data?.data.code_id, code: promocodeToApply, discount: data?.data.discount }));
            setPromocodeError('');
        }
        if (error) {
            setPromocodeError(error as string);
        }
    };

    const handleResetPromocode = () => {
        setPromocodeValue('');
        dispatch(resetPromocode());

        document.cookie = 'promocode=; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    };

    const handleUpdatePromocodeValue = (value: string) => {
        setPromocodeError('');
        setPromocodeValue(value);
    };

    useEffect(() => {
        if (promocode) {
            applyPromocode();

            setPromocodeValue(promocode);
        }
    }, [promocode]);

    useEffect(() => {
        if (promocodeValue && promocode && selectedProducts.length) {
            applyPromocode();
        }
    }, [selectedProducts, promocode, promocodeValue]);

    return {
        promocodeValue,
        setPromocodeValue: handleUpdatePromocodeValue,
        applyPromocode,
        promocodeError,
        handleResetPromocode,
    };
};

export const useBasketMemory = () => {
    const dispatch = useAppDispatch();
    const selectedProducts = useAppSelector((state) => state.cart.data);
    const [loading, setLoading] = useState(true);
    const { handleResetPromocode } = usePromocode();

    const handleResetBasket = () => {
        dispatch(resetBasket());
        handleResetPromocode();
    };

    const handleDecreaseCount = useCallback(
        (id: number) => async () => {
            await dispatch(
                decreaseElement({
                    id,
                }),
            );
        },
        [dispatch],
    );

    const handleIncreaseCount = useCallback(
        (id: number) => async () => {
            await dispatch(
                increaseElement({
                    id,
                }),
            );
        },
        [dispatch],
    );

    useEffect(() => {
        if (!loading) {
            // Сохранение корзины в localStorage при изменении состояния корзины
            localStorage.setItem('cart', JSON.stringify(selectedProducts));
        }
    }, [selectedProducts, loading]);

    return {
        loading,
        onReset: handleResetBasket,
        onDecrease: handleDecreaseCount,
        onIncrease: handleIncreaseCount,
        setLoading,
    };
};
